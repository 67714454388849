import axios from "axios";
import websiteURL from "@/websiteURL";

export async function EditProduct(productId, product) {
  try {
    const apiUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/${productId}`;

    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const productResponse = await axios.put(apiUrl, product, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    return productResponse.data.permalink;
  } catch (error) {
    console.error("Error editing product:", error);
    throw error;
  }
}

//-------------------------------------------------//
export async function AddProduct(product) {
  product.manage_stock = true;

  try {
    const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products";
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const productResponse = await axios.post(apiUrl, product, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });
    return productResponse.data.permalink;
  } catch (error) {
    console.error("Error adding product and variations:", error);
    throw error;
  }
}

export async function getParentCategories() {
  try {
    const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/categories";
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const params = {
      context: "view",
      per_page: 100,
      order: "asc",
      orderby: "name",
      hide_empty: "false",
      _fields: "id,name,parent",
    };

    const response = await axios.get(apiUrl, {
      params,
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
}
export async function getBrands() {
  try {
    const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/brands";
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const params = {};

    const response = await axios.get(apiUrl, {
      params,
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    const brands = response.data;
    return brands;
  } catch (error) {
    console.error("Erreur lors de la récupération des marques :", error);
    return [];
  }
}
export async function getAttributeTerms() {
  try {
    const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/attributes";
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;

    const attributesResponse = await axios.get(apiUrl, {
      params: {
        _fields: "id,name,terms",
      },
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    const attributes = attributesResponse.data;
    const termPromises = [];

    for (const attribute of attributes) {
      const termsUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/attributes/${attribute.id}/terms`;

      termPromises.push(
        axios.get(termsUrl, {
          params: {
            _fields: "id,name",
          },
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        })
      );
    }

    // Wait for all term requests to complete in parallel
    const termResponses = await Promise.all(termPromises);

    // Combine attributes with their terms
    const allTerms = attributes.map((attribute, index) => {
      attribute.terms = termResponses[index].data;
      return attribute;
    });

    return allTerms;
  } catch (error) {
    console.error("Error fetching attributes and terms:", error);
    return [];
  }
}
